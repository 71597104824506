import { createTheme } from '@mui/material/styles'
const ThemeLightConfig = createTheme({
	palette: {
		mode: 'light',
		primary: { light: '#87d9fc', main: '#10B4F9', dark: '#0b7dae', contrastText: '#fff' },
		secondary: { light: '#6991a1', main: '#064863', dark: '#021c27', contrastText: '#fff' }
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: { scrollBehavior: 'smooth' },
				a: { textDecoration: 'none' }
			}
		}
	}
})

export default ThemeLightConfig
