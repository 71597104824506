// Types
import type { ReduxThunkAction } from '@/types/helpers/redux/store'
import type { APIUserTokenItem } from '@/types/api/items/user'

// Slices
import { userSlice } from '@/helpers/redux/user'

// Selectors
import { selectToken } from '@/helpers/redux/user/selectors'

export const setTokenUser =
	(token: string): ReduxThunkAction =>
	(dispatch, getState) => {
		const currentToken = selectToken(getState())

		if (currentToken != token) dispatch(userSlice.actions.setToken(token))
	}

export const setDataUser =
	(data: APIUserTokenItem): ReduxThunkAction =>
	dispatch => {
		dispatch(userSlice.actions.setData(data))
	}
