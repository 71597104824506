import { createSlice } from '@reduxjs/toolkit'
import { getCookie, setCookie } from 'cookies-next'

// Types
import type { SettingsSliceState } from '@/types/helpers/redux/settings'

// Configurations
import Config from '@/configs'

// State
const initialState: SettingsSliceState = {
	mode: 'system',
	mediaTree: true,
	mediaShowType: 'grid'
}

// Get mode
const mode = getCookie(`${Config.shortName.toLocaleLowerCase()}-mode`)

if (mode && ['system', 'dark', 'light'].includes(mode)) initialState.mode = mode

// Get media tree
const mediaTree = getCookie(`${Config.shortName.toLocaleLowerCase()}-media-tree`)

if (mediaTree) initialState.mediaTree = mediaTree === '1' || mediaTree === 'true'

// Get media show type
const mediaShowType = getCookie(`${Config.shortName.toLocaleLowerCase()}-media-show-type`)

if (mediaShowType && ['grid', 'list'].includes(mediaShowType)) initialState.mediaShowType = mediaShowType

// Slice
export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setMode: (state, action) => {
			state.mode = action.payload

			// Set cookie
			setCookie(`${Config.shortName.toLocaleLowerCase()}-mode`, state.mode)
		},
		setMediaTree: (state, action) => {
			state.mediaTree = action.payload

			// Set cookie
			setCookie(`${Config.shortName.toLocaleLowerCase()}-media-tree`, state.mediaTree)
		},
		setMediaShowType: (state, action) => {
			state.mediaShowType = action.payload

			// Set cookie
			setCookie(`${Config.shortName.toLocaleLowerCase()}-media-show-type`, state.mediaShowType)
		}
	}
})
