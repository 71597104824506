// Types
import type { ReduxThunkAction } from '@/types/helpers/redux/store'

// Slices
import { settingsSlice } from '@/helpers/redux/settings'

// Selectors
import { selectMode, selectMediaTree, selectMediaShowType } from '@/helpers/redux/settings/selectors'

export const setModeSetting =
	(mode: 'system' | 'dark' | 'light'): ReduxThunkAction =>
	(dispatch, getState) => {
		const currentMode = selectMode(getState())

		if (currentMode != mode) dispatch(settingsSlice.actions.setMode(mode))
	}

export const setMediaShowTypeSetting =
	(mediaShowType: 'grid' | 'list'): ReduxThunkAction =>
	(dispatch, getState) => {
		const currentMediaShowType = selectMediaShowType(getState())

		if (currentMediaShowType != mediaShowType) dispatch(settingsSlice.actions.setMediaShowType(mediaShowType))
	}

export const setMediaTreeSetting =
	(mediaTree: boolean): ReduxThunkAction =>
	(dispatch, getState) => {
		const currentMediaTree = selectMediaTree(getState())

		if (currentMediaTree != mediaTree) dispatch(settingsSlice.actions.setMediaTree(mediaTree))
	}
