import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCookie, setCookie, deleteCookie } from 'cookies-next'

// Configurations
import Config from '@/configs'

// Types
import type { UserSliceState } from '@/types/helpers/redux/user'
import type { APISessionCheckTokenData } from '@/types/api/session/check-token'
import type { APIUserTokenItem } from '@/types/api/items/user'

// Helpers
import API from '@/helpers/api'

// State
const initialState: UserSliceState = { loading: false }

// Get token
const token = getCookie(`${Config.shortName.toLocaleLowerCase()}-token`)

if (token) initialState.token = token.toString()

// Get
export const userGetData = createAsyncThunk('user/get-data', async (token: string) => {
	const result: APISessionCheckTokenData = await API({
		url: '/session/check-token',
		type: 'POST',
		token
	})

	if (result.status && result.data && result.data.item) return result.data.item
	else return null
})

// Slice
export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload

			// Set cookie
			if (state.token !== '') setCookie(`${Config.shortName.toLocaleLowerCase()}-token`, state.token)
			else deleteCookie(`${Config.shortName.toLocaleLowerCase()}-token`)
		},
		setData: (state, action) => {
			state.data = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(userGetData.pending, state => {
				state.loading = true
			})
			.addCase(userGetData.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload as APIUserTokenItem
			})
	}
})
