// Configuration
import APIConfig from '@/configs/api'

// Types
import type { APIProps } from '@/types/helpers/api'

const API = async (props: APIProps) => {
	// Props
	const { url, type, token } = props

	// Result
	try {
		const response = await fetch(`${APIConfig.url}${url}`, {
			method: type,
			headers: token ? { Authorization: `Bearer ${token}` } : {},
			cache: 'no-cache'
		})

		return await response.json()
	} catch (err) {
		return { status: false, statusCode: 404 }
	}
}

export default API
